<template>
  <main class="page-favorites">
    <div class="main-grid">
      <div class="aside">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ name: 'home' }">
            <font-awesome-icon :icon="[ 'fas', 'home' ]" />
          </el-breadcrumb-item>
          <el-breadcrumb-item>我的收藏</el-breadcrumb-item>
        </el-breadcrumb>
        <h4>我的收藏</h4>
        <section></section>
        <section>
          <RecentQuery>
          </RecentQuery>
        </section>
      </div>
      <div class="container">
        <div class="container-hint">
          共 {{totalCount}} 筆收藏
        </div>
        <div
          class="company-list-set"
          v-loading="isLoading">
          <div class="company-list-toolbar">
            <div class="toolbar-left">
              <el-select
                size="mini"
                v-model="sortValue"
                placeholder="排序"
                @change="searchCollections">
                <el-option
                  v-for="item in collectionsSortOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="toolbar-right">
              <el-pagination
                layout="prev, pager, next, jumper"
                :total="totalCount"
                :current-page.sync="currentPage"
                @current-change="searchCollections">
              </el-pagination>
            </div>
          </div>
          <!-- <CompanyList
            style="min-height:100%"
            :companyList="companyList">
          </CompanyList> -->
          <ul
            class="company-list">
            <CompanyCard
              v-for="company in companyList"
              :key="company.uniID"
              :company="company">
              <template #collectionButton="{ company }">
                <el-button
                  type="text"
                  class="heart"
                  @click="toggleCollection(company)">
                  <font-awesome-icon
                    :icon="[ 'fas', 'heart' ]"
                    class="color-danger"/>
                </el-button>
              </template>
            </CompanyCard>
          </ul>
          <div class="align-right">
            <el-pagination
              layout="prev, pager, next, jumper"
              :total="totalCount"
              :current-page.sync="currentPage"
              @current-change="searchCollections">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <MainPageFloatButton
      :downloadAction="downloadAction">
    </MainPageFloatButton>
  </main>
</template>

<script>
import * as apis from '@/apis/index.ts'
import * as codes from '@/const/codes.ts'
import utils from '@/utils/utils.js'
import moment from 'moment'
import { mapState } from 'vuex'
import collectionsSortOptions from '@/const/collectionsSortOptions.js'
import RecentQuery from '@/components/RecentQuery/RecentQuery.vue'
import CompanyCard from '@/components/CompanyCard/CompanyCard.vue'
// import CompanyList from '@/components/CompanyList/CompanyList.vue'
import MainPageFloatButton from '@/components/MainPageFloatButton/MainPageFloatButton.vue'
import parseCompanyListExcel from '@/utils/parseCompanyListExcel.js'

const defaultSortValue = collectionsSortOptions[0]

export default {
  components: {
    RecentQuery,
    CompanyCard,
    MainPageFloatButton
    // CompanyList
  },
  data () {
    return {
      collectionsSortOptions,
      isLoading: true,
      companyList: [],
      totalCount: 0,
      sortValue: defaultSortValue.value,
      currentPage: 1,
      currentSearchRequest: {},
      companyTags: [
        '紡織',
        '傳產',
        '創新研發'
      ],
      addNewTagInput: '',
      addNewTagInputVisible: false
    }
  },
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  methods: {
    searchCollections () {
      const sortData = JSON.parse(this.sortValue)
      const query = {
        page_num: this.currentPage,
        by: sortData.by,
        order: sortData.order
      }
      this.$router.replace({ query })

    },
    addNewTag () {
      this.addNewTagInputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveNewTagInput.$refs.input.focus()
      })
    },
    handleInputConfirm () {
      let addNewTagInput = this.addNewTagInput
      if (addNewTagInput) {
        this.companyTags.push(addNewTagInput)
      }
      this.addNewTagInputVisible = false
      this.addNewTagInput = ''
    },
    toggleCollection (company) {
      this.$confirm('是否確定移除收藏？', '提示', {
        confirmButtonText: '確定移除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(data => {
        apis.collectionsDelete({
          email: this.userInfo.email,
          companys: [company.uniID]
        }).then(d => {
          // 重新查詢
          this.search()
        })
      }).catch(() => {
      })
    },
    async search () {
      let companyList = []
      let companyUniIDs = []
      this.isLoading = true
      // -- 查詢收藏名單 --
      const collectionsResult = await apis.collections(this.currentSearchRequest)
      
      if (!collectionsResult || !collectionsResult.payload || !collectionsResult.payload.uniIDs) {
        return
      }
      companyUniIDs = collectionsResult.payload.uniIDs
      this.totalCount = collectionsResult.payload.total_count
      // -- 查詢公司資料列表 --
      const companyListResult = await apis.companyListGet({
        basic: {
          get: ['list'],
          keyword: companyUniIDs.join('|'), // 用聯集查詢
          keyword_type: 'tax_number'
        }
      })
      this.isLoading = false
      // -- 重新排序 --
      if (!companyListResult.payload || !companyListResult.payload.list) {
        return
      }
      companyUniIDs.forEach(uniID => {
        let item = companyListResult.payload.list.find(d => d.uniID === uniID)
        companyList.push(item)
      })
      // 寫入
      this.companyList = companyList
    },
    // 下載
    async downloadAction () {
      try {
        const sortValue = JSON.parse(this.sortValue)
        let searchQuery = {
          email: this.userInfo.email,
          page_limit: 10000,
          page_num: 1,
          by: sortValue.by,
          order: sortValue.order
        }

        // -- 查詢收藏名單 --
        const collectionsResult = await apis.collections(searchQuery)
        
        if (!collectionsResult || !collectionsResult.payload || !collectionsResult.payload.uniIDs) {
          return
        }
        const companyUniIDs = collectionsResult.payload.uniIDs
        // -- 查詢公司資料列表 --
        const result = await apis.companyListGet({
          basic: {
            get: ['list'],
            'page-size': 10000,
            keyword: companyUniIDs.join('|'), // 用聯集查詢
            keyword_type: 'tax_number'
          }
        })
        
        // let thisYear = moment().year() - 1911
        // let thisYear = moment().year()
        // let lastYear = thisYear - 1
        // let theYearBeforLast = lastYear - 1

        await import('@/vendor/Export2Excel.js').then(excel => {
          
          const { data, header } = parseCompanyListExcel(result.payload.list)

          excel.export_json_to_excel({
            header, //表头 必填
            data, //具体数据 必填
            filename: `我的收藏_${moment().format('YYYY_MM_DD_HH_mm')}`, //非必填
            autoWidth: true, //非必填
            bookType: 'xlsx' //非必填
          })

          return
        })

        return true
      } catch (e) {
        console.error(e)
      }
    }
  },
  watch: {
    '$route.query': {
      async handler (query) {
        // -- 查詢參數 --
        let page_num = defaultSortValue.data.page_num // default
        let by = defaultSortValue.data.by // default
        let order = defaultSortValue.data.order // default
        if (query.page_num) {
          this.currentPage = parseInt(query.page_num)
          page_num = this.currentPage
        }
        if (query.by && query.order) {
          let option = this.collectionsSortOptions.find(d => {
            if (d.data.by === query.by && d.data.order === query.order) {
              return true
            }
            return false
          })
          this.sortValue = option.value
          by = query.by
          order = query.order
        }
        this.currentSearchRequest = {
          email: this.userInfo.email,
          page_limit: 10,
          page_num,
          by,
          order
        }
        // -- 查詢 --
        this.search()
      },
      immediate: true
    }
  }
}
</script>
