export default [
  {
    label: '依收藏時間由近至遠',
    value: '{"by":"datetime_update","order":"desc"}',
    data: {"by":"datetime_update","order":"desc"}
  },
  {
    label: '依收藏時間由遠至近',
    value: '{"by":"datetime_update","order":"asc"}',
    data: {"by":"datetime_update","order":"asc"}
  },
  // {
  //   label: '依資本額由高至低',
  //   value: '{"by":"capital_amount","order":"desc"}',
  //   data: {"by":"capital_amount","order":"desc"}
  // },
  // {
  //   label: '依資本額由低至高',
  //   value: '{"by":"capital_amount","order":"asc"}',
  //   data: {"by":"capital_amount","order":"asc"}
  // },
  {
    label: '依登記筆畫由高至低',
    value: '{"by":"company_name","order":"desc"}',
    data: {"by":"company_name","order":"desc"}
  },
  {
    label: '依登記筆畫由低至高',
    value: '{"by":"company_name","order":"asc"}',
    data: {"by":"company_name","order":"asc"}
  }
]
